<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddPaymentCondition"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('PAYMENTCOND.ADD_PAYMENTCOND.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('PAYMENTCOND.ADD.LABEL.NAME')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENTCOND.ADD.LABEL.PLACEHOLDER.NAME')" v-model="data.name"  :danger="name_fail" val-icon-danger="clear"/>
            <vs-input :label="$t('PAYMENTCOND.ADD.LABEL.TEXT')"  class="float-left mr-2 inputx" :placeholder="$t('PAYMENTCOND.ADD.LABEL.PLACEHOLDER.TEXT')" v-model="data.text" :danger="text_fail" val-icon-danger="clear"/>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
              <vs-input-number v-model="data.skonto_days" min="0" step="1" max="50" :label="$t('PAYMENTCOND.ADD.LABEL.SKONTO_DAYS')" class="inputx" />
            </div>
            <div  class="float-left mr-2">
              <vs-input-number v-model="data.skonto_proz" min="0" max="50" step="0.5" :label="$t('PAYMENTCOND.ADD.LABEL.SKONTO_PROZ')" class="inputx" />
            </div>
            <div  class="float-left mr-2">
              <vs-input-number v-model="data.payableInDays" min="0" step="1" :label="$t('PAYMENTCOND.ADD.LABEL.PAYABLE_DAYS')" class="inputx" />
            </div>            
          </div>  
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
              <vs-checkbox v-model="data.isDefault">{{$t('PAYMENTCOND.ADD_PAYMENTCOND.LABEL.ISDEFAULT')}}</vs-checkbox>
            </div>
          </div>  
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddPaymentConditionDlg",
  props:[],
  components:{
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('PAYMENTCOND.ADD_PAYMENTCOND.TITLE'),
      name_fail:false,
      name_ok:false,
      text_fail:false,
      text_ok:false,
      data:this.GetEmptyData()
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('PAYMENTCOND.ADD_PAYMENTCOND.TITLE');
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.popupTitle = this.$t('PAYMENTCOND.ADD_PAYMENTCOND.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      accepted(){

          this.$store.dispatch('paymentcondition/savePaymentCondition', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PAYMENTCOND.ADD_PAYMENTCOND.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('PAYMENTCOND.ADD_PAYMENTCOND.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PAYMENTCOND.ADD_PAYMENTCOND.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          name:"",
          text:"",
          company_fk:-1,
          skonto_days:0,
          skonto_proz:2,
          payableInDays:0,
          isDefault:false
        };
      }
  },
  computed:{
    formValid(){
      return this.name_ok && this.text_ok;
    },
    name(){
      return this.data.name;
    },
    text(){
      return this.data.text;
    }
  },
  watch:{
    name(value)
    {
       this.name_fail = value.length == 0;
       this.name_ok = !this.name_fail;
    },
    text(value)
    {
      this.text_fail = value.length == 0;
      this.text_ok = !this.text_fail;
    }
  }
};
</script>
<style>
div#promptAddPaymentCondition > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddPaymentCondition > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>